<template>
    <div>
        <v-overlay class="p-absolute" opacity="0.8" v-if="loading">
            <v-progress-circular :size="60" :width="6" color="primary" indeterminate></v-progress-circular>
        </v-overlay>

        <v-container px-5 pb-6 v-if="!resetSent && !error" class="align-center d-flex flex-column">
            <form @submit.prevent="submit">
                <v-row justify="center">
                    <v-col>
                        <h2>Forgot Password</h2>
                        <p>Enter your email below, and we'll send you a link to reset your password</p>
                    </v-col>
                </v-row>
                <v-row dense>
                    <v-col cols="12">
                        <v-text-field
                            ref="email"
                            v-model="email"
                            type="email"
                            class="pt-0 my-5 outline"
                            label="Email Address"
                            placeholder="Please enter your email..."
                            required
                            :rules="[rules.required, rules.email]"
                            hint="Enter your email to reset your password"
                            persistent-hint
                        />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-btn block x-large type="submit"> Send Reset Link </v-btn>
                    </v-col>
                </v-row>
                <!-- <v-row>
          <v-col cols="12" align="center" justify="center">
            <v-btn :to="{ name: 'auth.login' }" small text >
              <span class="text-capitalize font-weight-regular">Log In</span>
            </v-btn>
          </v-col>
        </v-row> -->
            </form>
        </v-container>
        <v-container px-5 pb-6 v-else-if="error" class="align-center d-flex flex-column">
            <v-row>
                <v-col class="shrink d-flex justify-center" cols="12">
                    <h2 class="text-no-wrap text-center mx-auto">Reset Password</h2>
                </v-col>
                <v-col>
                    <p class="text-muted text-center">
                        An error occured while trying to send your password reset request. Please try again.
                    </p>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-btn block x-large dark @click="error = false"> Send Reset Link </v-btn>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" align="center" justify="center">
                    <v-btn :to="{ name: 'auth.login' }" small text>
                        <span class="text-capitalize font-weight-regular">Log In</span>
                    </v-btn>
                </v-col>
            </v-row>
        </v-container>
        <v-container px-5 pb-6 v-else class="">
            <v-row>
                <v-col class="shrink" cols="12">
                    <h2 class="text-no-wrap text-center mt-6 mb-3 mx-auto">Reset Password</h2>
                    <p class="text-muted text-center">A password reset email has been sent.</p>
                    <p class="text-muted text-center">
                        It may take a few minutes to recieve this. If you do not recieve this please check that you have
                        entered the correct email address or check your junk mail.
                    </p>
                </v-col>
            </v-row>
            <!-- <v-row>
        <v-col cols="12">
          <v-btn block x-large  :to="{ name: 'auth.login' }">
            Back to Log in
          </v-btn>
        </v-col>
      </v-row> -->
        </v-container>
    </div>
</template>

<script>
import _ from "lodash";
import UserMixin from "@/mixins/user-mixin";
import { mapState } from "vuex";
/////////////////////////////////////////////////////////

export default {
    mixins: [UserMixin],
    meta: {
        titleTemplate: "Forgot Password | %s",
    },
    mounted() {
        if (!this.email || !this.email.length) {
            return this.$refs.email.focus();
        }
    },
    data() {
        return {
            loading: false,
            resetSent: false,
            error: false,
            rules: {
                required: (value) => !!value || "This field is required",
                email: (value) => {
                    const pattern =
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    return pattern.test(value) || "Invalid e-mail";
                },
            },
        };
    },
    methods: {
        submit() {
            this.loading = true;

            //Check if there is an application
            var applicationContext = this.application && this.application.authenticationStyle == "application";

            this.$fluro.auth
                .sendResetPasswordRequest(
                    {
                        username: this.email,
                        //This is the route that will receive the token
                        //for the user to reset their password
                        redirect: `/auth/reset`,
                    },
                    {
                        bypassInterceptor: true,
                        application: applicationContext, //Reset from the application
                    }
                )
                .then(
                    () => {
                        this.resetSent = true;
                        this.loading = false;
                    },
                    () => {
                        this.error = true;
                        this.loading = false;
                    }
                );
        },
    },
    computed: {
        ...mapState(["assets"]),
        // ...mapState(['email']),
        email: {
            set(email) {
                this.$store.commit("email", email);
            },
            get(email) {
                return this.$store.getters.email;
            },
        },
    },
};
</script>

<style scoped>
/*.width-half {
  width: 60%;
}

.white-label label {
  color: white !important;
  border-color: white;
}
.white-button button {
  color: white !important;
  border-color: white;
}

.white-text input {
  color: white !important;
  border-color: white;
}
.v-text-field > .v-input__control > .v-input__slot:before {
  border-color: white !important;
  border-style: solid;
  border-width: thin 0 0 0;
}*/
</style>
